.header {
	.navWrap {
		min-height: 70px;
		align-items: center;
		//margin-bottom: 40px;
	}
	.mainNav {
		//width: calc(100% - 170px);
		//background-color: red;
		order: 0;
		background-image: url("../img/menu-pencil.jpg");
		background-size: 386px 82px;
		background-position: left center;
		min-height: 70px;
		background-repeat: no-repeat;
		@media (max-width: 525px) {
			background-position: right center;
		}
		@include b-ML() {
			min-height: 80px;

			background-size: 386px 82px;
		}
	}
	.social {
		//width: 130px;
		text-align: right;
		//background-color: green;
		padding-left: 0px !important;
		padding-right: 0px !important;
		order: 2;

		@media (max-width: 1600px) {
		}
		@include b-ML() {
			order: 1;
		}

		a {
			margin-right: 30px;
			@include b-ML() {
				margin-right: 5px;
				margin-left: 50px;
			}
		}
		img {
			margin-left: 10px;
			width: 43px;
			height: 43px;
			margin-right: 10px;
		}
	}
	.mainTitle {
		text-align: center;
		//background-color: yellow;
		order: 1;
		padding-right: 30px;
		@include b-ML() {
			order: 2;
			width: 100%;
			box-sizing: border-box;
			margin-right: 27px;
			margin-left: 45px;
			margin-top: 15px;
		}
	}
}
.home {
	padding-right: 8px;
	@include b-ML() {
		padding-right: 0px;
	}
}
.scrollHolder {
	//background-color: red;
	//vyška viewportu - vyška menu - horní svorky - spodní okraj
	//height: calc(100vh - 109px - 46px - 27px);

	position: relative;
	color: #ffffff;

	@media (max-width: 1340px) {
		margin-left: 0px;
	}

	h1,
	h2 {
		text-align: center;
		margin-bottom: 20px;
		font-size: 1.2rem;
		@include b-ML() {
			font-size: 1rem;
		}
	}

	&--leftHand {
		width: 340px;
		height: 259px;
		position: fixed;
		bottom: 24px;
		left: calc(50% - 640px - 304px);
		@media (max-width: 1420px) {
			display: none;
		}
	}
	&--rightHand {
		width: 358px;
		height: 297px;
		position: fixed;
		bottom: 26px;
		left: calc(50% + 597px);
		@media (max-width: 1420px) {
			display: none;
		}
	}
	&--martin,
	&--tom {
		width: calc(50% - 20px);
		float: left;
		height: calc(100vh - 117px - 46px - 0px);
		//background-color: $green;
		//position: absolute;
		//height: 100%;
		//left: 0;
		//top: 0;
		overflow: hidden;
		@include b-TS() {
			//height: calc(100vh - 117px - 36px);
			height: calc(100vh - 117px - 14px);
			width: calc(50% - 10px);
		}
	}

	&--tom {
		float: right;
	}

	&--move {
		position: relative;
		top: 0px;
	}
	&--link {
		//@include trans();

		img {
			@include trans();
			transform: rotate(0deg);
			width: 100%;
		}
		&:nth-child(odd) {
			&:hover {
				img {
					@include trans();
					transform: rotate(3deg);
				}
			}
		}
		&:nth-child(even) {
			&:hover {
				img {
					@include trans();
					transform: rotate(-3deg);
				}
			}
		}
	}

	.grid {
		box-sizing: content-box;
		.grid-sizer,
		.grid-item {
			width: calc(33.333% - 10px);
			box-sizing: content-box;

			@include b-TS() {
				width: calc(49% - 10px);
			}
			@include b-ML() {
				width: calc(100% - 10px);
			}
		}
		.grid-item {
			margin-bottom: 10px;
		}
		.grid-top {
			width: 100%;
		}
	}

	.grid2 {
		box-sizing: content-box;
		.grid-sizer,
		.grid-item2 {
			width: calc(33.333% - 10px);
			box-sizing: content-box;

			@include b-TS() {
				width: calc(49% - 10px);
			}
			@include b-ML() {
				width: calc(100% - 10px);
			}
		}
		.grid-item2 {
			margin-bottom: 10px;
		}
		.grid-top {
			width: 100%;
		}
	}
}
.toTop {
	margin: 20px 0px;
	background-color: transparent;
	display: block;
	text-align: center;
	padding: 10px 5px;
	width: 100%;
	border: none;
	font-size: 1rem;
	outline: none;
	cursor: pointer;
	&:focus,
	&:hover {
		outline: none;
	}

	span {
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
		display: inline-block;
		font-size: 2.5rem;
	}
}
.menuOpen {
	width: 24px;
	height: 24px;
	display: inline-block;
	background-color: transparent;
	border: none;
	&:focus,
	&:hover {
		outline: none;
	}
	background-image: url("../img/icon_menu_open.svg");
	background-size: 24px 24px;
	background-repeat: no-repeat;
	background-position: center center;
	margin-top: 17px;
	margin-left: 40px;

	&.uk-open {
		background-image: url("../img/icon_menu_close.svg");
	}
	@media (max-width: 959px) {
		margin-left: 30px;
	}
	@include b-ML() {
		margin-top: 23px;
		margin-left: 15px;
	}
}
.uk-dropdown {
	background: transparent;
	@media (max-width: 1600px) {
		background: #ffffff;
	}
}
.os-scrollbar-corner {
	display: none;
}
.art {
	h1 {
		text-align: center;
		margin-bottom: 40px;
	}
	&--txt {
		padding-top: 40px;
		//padding-bottom: 20px;
		display: block;
		width: 100%;
	}
	&--txtNopad {
		padding-top: 0px;		
		display: block;
		width: 100%;
	}
	&--textWrap {
		padding-top: 40px;
	}
	&--modStart {
		text-align: right;
		//padding-bottom: 20px;
	}
	&--nextPrev {
		font-size: 2.5rem;
		margin-bottom: 40px !important;
		div {
			&:last-child {
				text-align: right;
			}
		}
		a {
			text-decoration: none;
			@include trans();

			&:hover {
				text-decoration: none;
				@include trans();
				color: $orange;
			}
		}
	}
	&--btn {
		border: none;
		background-color: $txt;
		color: $white;
		@include trans();
		padding: 5px 15px;
		border-radius: 0px;
		font-size: 1rem;
		&:hover {
			text-decoration: none;
			@include trans();
			background-color: $orange;
		}
	}
	&--link {
		@include trans();

		img {
			@include trans();
			transform: rotate(0deg);
			width: 100%;
		}
		&:nth-child(odd) {
			&:hover {
				img {
					@include trans();
					transform: rotate(3deg);
				}
			}
		}
		&:nth-child(even) {
			&:hover {
				img {
					@include trans();
					transform: rotate(-3deg);
				}
			}
		}
	}

	a[href*="vimeo"],
	a[href*="youtu"] {
		//position: relative;
		&::before {
			position: absolute;
			width: 60px;
			height: 60px;
			content: "";
			z-index: 2;
			left: 50%;
			margin-left: -30px;
			top: 50%;
			margin-top: -30px;
			background-size: 60px 60px;
			opacity: 0.5;
			background-image: url("../img/play.svg");
			background-repeat: no-repeat;
			@include b-ML() {
				width: 30px;
				height: 30px;
				margin-left: -15px;
				margin-top: -15px;
				background-size: 30px 30px;
			}
		}
	}

	.grid {
		box-sizing: content-box;
		.grid-sizer,
		.grid-item {
			width: calc(33.333% - 10px);
			box-sizing: content-box;

			@include b-ML() {
				width: calc(50% - 10px);
			}
		}
		.grid-item {
			margin-bottom: 10px;
		}
		.grid-top {
			width: 100%;
		}
	}
}
.uk-modal-body {
	label {
		margin-bottom: 5px;
		display: block;
	}
}
.qainp {
	width: calc(100% - 100px);
	display: block;
	float: right;
}
.qcimg {
	display: block;
	float: left;
}
.uk-button-primary {
	border: none;
	background-color: $txt;
	color: $white;
	@include trans();
	padding: 5px 15px;
	border-radius: 0px;
	font-size: 1rem;
	&:hover {
		text-decoration: none;
		@include trans();
		background-color: $orange;
	}
}
.er {
	background-color: $red;
	color: $white;
	padding: 10px;
}
.ch {
	background-color: $green;
	color: $white;
	padding: 10px;
}

.contact {
	&--brush {
		position: fixed;
		bottom: 70px;
		left: 0px;
		width: 300px;
		height: 189px;
		@media (max-width: 1150px) {
			width: 150px;
			height: 95px;
		}
		@media (max-width: 880px) {
			display: none;
		}
		img {
			width: 100%;
			height: auto;
		}
	}

	&--wrap {
		width: 100%;
		max-width: 550px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 0px;

		h1 {
			text-align: center;
			margin-bottom: 40px;
		}
	}

	&--txt {
		box-sizing: content-box;
		background-image: url("../img/aboutusBack.jpg");

		-webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.17);
		-moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.17);
		box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.17);
		border-radius: 3px;
	}

	&--txtIn {
		box-sizing: content-box;
		background-image: url("../img/aboutusBack.jpg");
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		justify-content: center;
		padding: 40px;
		-webkit-box-shadow: inset 5px -5px 30px -9px rgba(184, 65, 57, 0.47);
		-moz-box-shadow: inset 5px -5px 30px -9px rgba(184, 65, 57, 0.47);
		box-shadow: inset 5px -5px 30px -9px rgba(184, 65, 57, 0.47);
		@include b-TS() {
			padding: 15px;
		}
	}

	&--cont {
		width: calc(50% - 20px);
		text-align: center;
		font-size: 1.1rem;
		@include b-ML() {
			width: calc(100%);
		}

		&:first-child {
			margin-right: 40px;
			@include b-ML() {
				margin-right: 0px;
				margin-bottom: 15px;
			}
		}
		&:last-child {
			margin-top: 40px;
			@include b-ML() {
				margin-top: 15px;
			}
		}

		a {
			@include trans();
			text-decoration: none;

			&:hover {
				color: $black;
			}
		}
	}
}

.aboutus {
	&--brush {
		position: fixed;
		bottom: 70px;
		left: 0px;
		width: 300px;
		height: 189px;
		@media (max-width: 1150px) {
			width: 150px;
			height: 95px;
		}
		@media (max-width: 1015px) {
			display: none;
		}
		img {
			width: 100%;
			height: auto;
		}
	}

	&--wrap {
		box-sizing: content-box;
		display: block;
		max-width: 590px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 0px;
		text-align: center;
		font-size: 1.2rem;
		padding: 40px;
		@include b-ML() {
			padding: 15px;
		}
		h1 {
			text-align: center;
			margin-bottom: 40px;
		}
	}

	&--handWrap {
		text-align: center;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		position: relative;
		z-index: 2;
		margin-top: 40px;
		img {
			max-width: 100%;
			@media (max-width: 750px) {
				width: 48%;
			}
			&:first-child {
				margin-right: 20px;
				@media (max-width: 750px) {
					margin-right: 3.8%;
				}
			}
		}
	}
}
.articles {
	//background-color: red;
	//vyška viewportu - vyška menu - horní svorky - spodní okraj
	//height: calc(100vh - 109px - 46px - 27px);

	position: relative;
	color: #ffffff;

	@media (max-width: 1340px) {
		margin-left: 0px;
	}

	h1,
	h2 {
		text-align: center;
		margin-bottom: 20px;
		font-size: 1.8rem;
		@include b-ML() {
			font-size: 1rem;
		}
	}

	&--move {
		position: relative;
		top: 0px;
	}
	&--link {
		img {
			-webkit-transition: transform 0.5s ease;
			-ms-transition: transform 0.5s ease;
			transition: transform 0.5s ease;
			transform: rotate(0deg);
			width: 100%;
		}
		&:nth-child(odd) {
			&:hover {
				img {
					-webkit-transition: transform 0.5s ease;
					-ms-transition: transform 0.5s ease;
					transition: transform 0.5s ease;
					transform: rotate(3deg);
				}
			}
		}
		&:nth-child(even) {
			&:hover {
				img {
					-webkit-transition: transform 0.5s ease;
					-ms-transition: transform 0.5s ease;
					transition: transform 0.5s ease;
					transform: rotate(-3deg);
				}
			}
		}

		img {
			-webkit-transition: transform 0.5s ease;
			-ms-transition: transform 0.5s ease;
			transition: transform 0.5s ease;
			transform: rotate(0deg);
			width: 100%;
		}
		float: left;
		background-color: #ffffff;
		display: block;
	}
	.grid {
		box-sizing: content-box;
		.grid-sizer,
		.grid-item {
			width: calc(16.6% - 10px);
			box-sizing: content-box;

			@include b-DS() {
				width: calc(20% - 10px);
			}
			@include b-TL() {
				width: calc(25% - 10px);
			}
			@include b-TS() {
				width: calc(33.333% - 10px);
			}
			@include b-ML() {
				width: calc(48% - 0px);
			}
			@include b-M() {
				width: calc(100% - 10px);
			}
		}
		.grid-item {
			margin-bottom: 10px;
		}
		.grid-top {
			width: 100%;
		}
	}
}

.gridIn {
		box-sizing: content-box;
		.gridIn-sizer,
		.gridIn-item {
			width: calc(33.333% - 10px);
			box-sizing: content-box;

			@include b-TS() {
				width: calc(49% - 10px);
			}
			@include b-ML() {
				width: calc(100% - 10px);
			}
		}
		.gridIn-item {
			margin-bottom: 10px;
		}
		.gridIn-top {
			width: 100%;
		}
	}

	.detail__1 {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		max-width: 800px;	
		



		.articles--link {
			float: none;
			width: 100%;
			margin-bottom: 10px;
			position: relative;
		}
	}


	.detail__2 {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		max-width: 1200px;
		display: flex;	
		@include b-M() {
				display: block;
			}
		.articles--link {
			position: relative;
			float: none;
			width: calc(50% - 10px);
			margin-bottom: 10px;

			@include b-M() {
					width: 100%;
			}

			&:first-child {
				margin-right: 20px;
				@include b-M() {
					margin-right: 0px;
				}
			}
		}	
	}

	.detail__imgOne {
		height: calc(100vh - 200px);		
		object-fit: contain;
		width: 100%;

		@media screen and (min-height: 1050px ) {
			height: calc(100vh - 400px);
		}
		@media screen and (max-height: 1050px ) {
			height: calc(100vh - 200px);
		}
		@media screen and (max-height: 650px ) {
			height: calc(100vh - 100px);
		}
	}