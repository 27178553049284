/* patrick-hand-regular - latin-ext_latin */
/*
@font-face {
	font-family: "Patrick Hand";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/patrick-hand-v13-latin-ext_latin-regular.eot"); 
	src: local("Patrick Hand"), local("PatrickHand-Regular"),
		url("../fonts/patrick-hand-v13-latin-ext_latin-regular.eot?#iefix")
			format("embedded-opentype"),
		 url("../fonts/patrick-hand-v13-latin-ext_latin-regular.woff2")
			format("woff2"),
		
			url("../fonts/patrick-hand-v13-latin-ext_latin-regular.woff")
			format("woff"),
		
			url("../fonts/patrick-hand-v13-latin-ext_latin-regular.ttf")
			format("truetype"),
		
			url("../fonts/patrick-hand-v13-latin-ext_latin-regular.svg#PatrickHand")
			format("svg"); 
}

font-family: 'Roboto Slab', serif;
light: 300;
regular: 400;
bold: 700;

*/
html {
	background-color: $back;
}
body {
	background-color: $back;
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-linehight;
	font-weight: normal;
	margin: 0;
	padding: 46px 0px 35px 0px;
	color: $txt;
	letter-spacing: 0rem;
	position: relative;
	z-index: 1;
	background-image: url("../img/topLoop.png");
	background-repeat: repeat-x;
	background-position: left top;
	@include b-menuM() {
		padding: 34px 0px 0px 0px;
	}
}

a,
img {
	border: none;
	outline: none;
}

html {
	font-size: $base-font-size;
	line-height: $base-linehight;
	margin: 0;
	padding: 0;
	color: $txt;
	scroll-behavior: smooth;
}

strong,
b {
	font-weight: bold;
	font-weight: 700;
}

input,
button {
	font-family: $base-font-family;
	font-weight: normal;
	color: $txt;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	color: $txt;
	font-family: $base-font-family-head;
	font-weight: 400;
	line-height: $base-linehight-head;
}

h1 {
	font-size: 2.0435rem;
	@include b-ML() {
		font-size: 1.5rem;
	}
}
h2 {
	font-size: 1.5rem;

	@include b-ML() {
		font-size: 1.2rem;
	}
}
a {
	cursor: pointer;
	color: $txt;
}
p {
	padding: 0;
	margin: 0px 0px 20px 0px;
}

.cont {
	//background-color: blue;
	max-width: $basic-width;

	//max-width: calc(100% - (2 * #{$spaceBig}));
	width: calc(100%);

	position: relative;
	margin-left: auto;
	margin-right: auto;
	height: 100%;

	padding: 0 $space;

	@include b-D() {
		padding: 0 $space;
		max-width: calc(100% - (2 * #{$space}));
	}
	@include b-DS() {
		max-width: calc(100%);
		padding: 0 $space;
	}

	@media (max-width: 730px) {
		padding: 0 $spaceF;
	}
}

.contM {
	//background-color: blue;
	max-width: $basic-width-mid;
	width: calc(100%);
	position: relative;
	margin-left: auto;
	margin-right: auto;
	height: 100%;

	padding: 0 $space;

	@include b-DS() {
		padding: 0 $space;
	}
	@media (max-width: 730px) {
		padding: 0 $spaceF;
	}
}

.contS {
	max-width: 375px;
	width: calc(100%);
	position: relative;
	margin-left: auto;
	margin-right: auto;
	height: 100%;

	padding: 0 20px;

	@media (max-width: 730px) {
		padding: 0 10px;
	}
}

.contNP {
	@extend .cont;
	padding: 0;
	@media (max-width: 730px) {
		padding: 0;
	}
}

.contNP-M {
	@extend .cont;
	padding: 0;
	@include b-D() {
		max-width: 100%;
		padding: 0 $spaceMid;
	}
	@media (max-width: 730px) {
		padding: 0 $spaceF;
	}
}

.cont-50 {
	width: calc((100% - #{$space}) / 2);
	float: left;
	margin-left: $space;

	&:nth-child(even) {
		margin-left: 0px;
	}

	@include b-TS() {
		width: 100%;
		float: none;
		margin-left: 0px;
		margin-bottom: $space;
	}
}

.cont-75 {
	width: calc(75% - (#{$space} / 2));
	float: left;

	@include b-TS() {
		width: 100%;
		float: none;
		margin-bottom: $space;
	}
}

.cont-25 {
	width: calc(25% - (#{$space} / 2));
	float: right;

	@include b-TS() {
		width: 100%;
		float: none;
		margin-bottom: $space;
	}
}

.cont-50L {
	width: calc(50% - (#{$space} / 2));
	float: left;

	@include b-TS() {
		width: 100%;
		float: none;
		margin-bottom: $space;
	}
}

.cont-50R {
	width: calc(50% - (#{$space} / 2));
	float: right;

	@include b-TS() {
		width: 100%;
		float: none;
		margin-bottom: $space;
	}
}

.fullimg {
	width: 100%;
}

.marginBB {
	margin-bottom: $space * 2;
	@include b-TS() {
		margin-bottom: $space;
	}
}
.marginB {
	margin-bottom: $space;
	@include b-TS() {
		margin-bottom: $space;
	}
}

.basic {
	padding: 65px 0px;
	background-color: $txt;
	@include b-TS() {
		padding: 35px 0px;
	}
}

.footRight {
	position: fixed;
	width: 27px;
	height: calc(100% - 46px - 27px);
	background-image: url("../img/block-right.jpg");
	background-size: 27px 100%;
	background-position: left bottom;
	right: 0px;
	top: 46px;
	z-index: 10;

	@include b-ML() {
		display: none;
	}
}
.footBottom {
	position: fixed;
	height: 27px;
	width: calc(100% - 27px);
	background-image: url("../img/block-bottom.jpg");
	background-size: 100% 27px;
	background-position: right bottom;
	left: 0px;
	bottom: 0px;
	z-index: 10;
	@include b-ML() {
		display: none;
	}
}
.footCorner {
	position: fixed;
	height: 27px;
	width: 27px;
	background-image: url("../img/block-corner.jpg");
	background-size: 27px 27px;
	background-position: right bottom;
	right: 0px;
	bottom: 0px;
	z-index: 10;
	@include b-ML() {
		display: none;
	}
}
.dev {
	background-color: rgba(0, 0, 0, 0.3);
	position: fixed;
	top: 0px;
	left: 0px;
	width: 150px;
	padding: 15px;
	font-size: 0.8rem;
	color: #ffffff;
	pre {
		font-size: 0.8rem;
		color: #ffffff;
	}
}
