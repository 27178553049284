// variables START

$basic-width: 1366px;
$basic-width-mid: 1366px;
$mid-width: 1200px;
$b-addBorder: "@media (max-width: (${basic-width} + (${space} * 2))) ";

//grid system

$a-land: "and (orientation: landscape)";
$a-port: "and (orientation: portrait)";
$land: "(orientation: landscape)";
$port: "(orientation: portrait)";
$b-LD: "(min-width: 1170px)";
$b-SD: "(max-width: 1169px)";
$b-LT: "(max-width: 991px)";
$b-STm: "(min-width: 768px)";
$b-ST: "(max-width: 767px)";
$b-LM: "(max-width: 650px)";
$b-M: "(max-width: 460px)";
$b-SM: "(max-width: 350px)";

//colours#E5D1D2
$txt: #909999;
$black: #000000;
$back: #ffffff;
$green: #61c250;
$green-light: #c5f030;
$blue: #00a7c2;
$white: #ffffff;
$red: #e41a50;
$line: #d2d5db;
$grey: #cacaca;
$orange: #eca86c;

$space: 40px;
$spaceF: 20px;
$spaceMid: 20px;
$spaceBig: 80px;

$base-font-family: "Caveat", sans-serif;
$base-font-family-head: "Caveat Brush", sans-serif;
$base-font-size: 23px;
$base-linehight: 130%;
$base-linehight-head: 100%;

$basic-hp-header-height: 100vh;
