/* BOOTSTRAP OVERWRITE START */

/*
* Let's target IE to respect aspect ratios and sizes for img tags containing SVG files
*
* [1] IE9
* [2] IE10+
*/
/* 1 */
.ie9 img[src$=".svg"] {
	width: 100%;
}
/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	img[src$=".svg"] {
		width: 100%;
	}
}

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;

	&::before {
		display: block;
		content: "";
	}
}

.embed-responsive-16by9 {
	&::before {
		padding-top: 56.25%;
	}
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.cf:before,
.cf:after {
	content: ".";
	display: block;
	height: 0;
	overflow: hidden;
}
.cf:after {
	clear: both;
}
.cf {
	zoom: 1;
}
